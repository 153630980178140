import React from 'react';
import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';


const IMAGES = [
        {
            src: "dist/gallery/0a7baae1-be82-4185-822b-8c770eb2fbc0@w1920.jpg",
            thumbnail: "dist/gallery/0a7baae1-be82-4185-822b-8c770eb2fbc0@h300.jpg",
            thumbnailWidth: 217,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/1e96899c-1d0c-4b1f-a336-6dc17fa8a72f@w1920.jpg",
            thumbnail: "dist/gallery/1e96899c-1d0c-4b1f-a336-6dc17fa8a72f@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/2d08d79e-5e6a-426d-b750-65b646d37c4a@w1920.jpg",
            thumbnail: "dist/gallery/2d08d79e-5e6a-426d-b750-65b646d37c4a@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/2fa96dae-3c75-4b12-b5b8-916437117091@w1920.jpg",
            thumbnail: "dist/gallery/2fa96dae-3c75-4b12-b5b8-916437117091@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/6d643428-dfaf-4254-8544-59fa1c220d4c@w1920.jpg",
            thumbnail: "dist/gallery/6d643428-dfaf-4254-8544-59fa1c220d4c@h300.jpg",
            thumbnailWidth: 217,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/07ffaedd-c273-4ce7-b40c-9a6ed2eb278c@w1920.jpg",
            thumbnail: "dist/gallery/07ffaedd-c273-4ce7-b40c-9a6ed2eb278c@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/4ee33a42-e190-4070-90b2-2e8523361cc5@w1920.jpg",
            thumbnail: "dist/gallery/4ee33a42-e190-4070-90b2-2e8523361cc5@h300.jpg",
            thumbnailWidth: 217,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/7bfc72cd-232e-4056-ab7a-c96d945c7397@w1920.jpg",
            thumbnail: "dist/gallery/7bfc72cd-232e-4056-ab7a-c96d945c7397@h300.jpg",
            thumbnailWidth: 285,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/7c55f49e-d4e0-4505-9188-03f19f040b35@w1920.jpg",
            thumbnail: "dist/gallery/7c55f49e-d4e0-4505-9188-03f19f040b35@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/8a75a20d-e07b-4e24-9ac9-b90c12ba4bfc@w1920.jpg",
            thumbnail: "dist/gallery/8a75a20d-e07b-4e24-9ac9-b90c12ba4bfc@h300.jpg",
            thumbnailWidth: 217,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/7c7c8dbd-d671-4377-a9e7-1866984445ea@w1920.jpg",
            thumbnail: "dist/gallery/7c7c8dbd-d671-4377-a9e7-1866984445ea@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/8c57c3ea-4874-4c4d-92ba-49cff778c203@w1920.jpg",
            thumbnail: "dist/gallery/8c57c3ea-4874-4c4d-92ba-49cff778c203@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/23a27fe4-1167-418b-b03a-edd230155d8e@w1920.jpg",
            thumbnail: "dist/gallery/23a27fe4-1167-418b-b03a-edd230155d8e@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/8ea16683-69bc-4ea1-b60f-060294874e30@w1920.jpg",
            thumbnail: "dist/gallery/8ea16683-69bc-4ea1-b60f-060294874e30@h300.jpg",
            thumbnailWidth: 217,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/4ef9a4bb-cb18-4816-8aa5-fb2b04c3ea5a@w1920.jpg",
            thumbnail: "dist/gallery/4ef9a4bb-cb18-4816-8aa5-fb2b04c3ea5a@h300.jpg",
            thumbnailWidth: 217,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/41eb85a6-6386-4833-a93b-2900382dcfe6@w1920.jpg",
            thumbnail: "dist/gallery/41eb85a6-6386-4833-a93b-2900382dcfe6@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/45e29d34-6368-4a3b-b1d6-0066aa6a11ee@w1920.jpg",
            thumbnail: "dist/gallery/45e29d34-6368-4a3b-b1d6-0066aa6a11ee@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/66c53044-6e0c-4cf7-a8ec-41307afdec89@w1920.jpg",
            thumbnail: "dist/gallery/66c53044-6e0c-4cf7-a8ec-41307afdec89@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/68ac6cec-cdae-4523-a813-f92a3ca68b24@w1920.jpg",
            thumbnail: "dist/gallery/68ac6cec-cdae-4523-a813-f92a3ca68b24@h300.jpg",
            thumbnailWidth: 217,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/094c8588-08d7-45ea-a38c-066526c2a0d2@w1920.jpg",
            thumbnail: "dist/gallery/094c8588-08d7-45ea-a38c-066526c2a0d2@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/677d1d98-90d6-4686-8732-c050cb002f65@w1920.jpg",
            thumbnail: "dist/gallery/677d1d98-90d6-4686-8732-c050cb002f65@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/4120abe1-d83b-4d48-a91a-d85a318f8852@w1920.jpg",
            thumbnail: "dist/gallery/4120abe1-d83b-4d48-a91a-d85a318f8852@h300.jpg",
            thumbnailWidth: 217,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/07888b9f-5ad9-47d8-9b91-a7d71f331608@w1920.jpg",
            thumbnail: "dist/gallery/07888b9f-5ad9-47d8-9b91-a7d71f331608@h300.jpg",
            thumbnailWidth: 217,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/59585c03-77dc-4802-982d-c0299f679559@w1920.jpg",
            thumbnail: "dist/gallery/59585c03-77dc-4802-982d-c0299f679559@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/49564632-549f-490b-988a-e6562ae5d6a8@w1920.jpg",
            thumbnail: "dist/gallery/49564632-549f-490b-988a-e6562ae5d6a8@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/a60c1bde-d8a8-43c0-9bbf-9b0479cbde16@w1920.jpg",
            thumbnail: "dist/gallery/a60c1bde-d8a8-43c0-9bbf-9b0479cbde16@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/aa2e1b02-41e2-47f8-b5e6-7208328b7f95@w1920.jpg",
            thumbnail: "dist/gallery/aa2e1b02-41e2-47f8-b5e6-7208328b7f95@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/c0e5b4f0-067e-4af5-ab9e-bfe3b64990d4@w1920.jpg",
            thumbnail: "dist/gallery/c0e5b4f0-067e-4af5-ab9e-bfe3b64990d4@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/db16d4ea-81ec-4e32-ac22-20f5876171a3@w1920.jpg",
            thumbnail: "dist/gallery/db16d4ea-81ec-4e32-ac22-20f5876171a3@h300.jpg",
            thumbnailWidth: 217,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/e01755e7-26ed-4354-85e8-61774b93ccad@w1920.jpg",
            thumbnail: "dist/gallery/e01755e7-26ed-4354-85e8-61774b93ccad@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/ee8213a2-b855-488c-9db7-f91d24a87f5d@w1920.jpg",
            thumbnail: "dist/gallery/ee8213a2-b855-488c-9db7-f91d24a87f5d@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        },
        {
            src: "dist/gallery/f9beaa9e-17a9-4f65-903e-2a0b51e0f552@w1920.jpg",
            thumbnail: "dist/gallery/f9beaa9e-17a9-4f65-903e-2a0b51e0f552@h300.jpg",
            thumbnailWidth: 385,
            thumbnailHeight: 290,
            caption: ""
        }
]

render(
        <Gallery images={IMAGES} enableImageSelection={false} rowHeight={290} margin={0}/>,
        document.getElementById('app')
);
