import React from 'react';
import { render } from 'react-dom';

import ReactAppSettings from './ReactAppSettings';
//import Cart from './components/CartInserter/Cart';

//render(<Cart/>, document.querySelector("#cart"));

import Gallery from './components/Gallery/Gallery';

/*Cart.Init();*/
